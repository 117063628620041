const Footer = () => {
  return (
    <>
      <div className="footer">
        <p>&#169; 2024 Emily Huang. All rights reserved.</p>
      </div>
    </>
  );
};

export default Footer;
